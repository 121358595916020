

export enum ChatClientEvent {

    CONNECT          = "connect",
    DISCONNECT       = "disconnect",
    INCOMING_MESSAGE = "chat:message",
    JOIN_ACCEPTED    = "chat:joinAccepted",

}
