import {AvatarType, isAvatarType} from "./AvatarType";
import isString from "lodash/isString";

export interface ChatMessage {

    readonly id      : string;
    readonly avatar  : AvatarType;
    readonly time    : string;
    readonly nickId  : string;
    readonly nick    : string;
    readonly message : string;

}

export function isChatMessage (value: any) : value is ChatMessage {

    return (
        !!value
        && isString(value?.id)
        && isAvatarType(value?.avatar)
        && isString(value?.time)
        && isString(value?.nickId)
        && isString(value?.nick)
        && isString(value?.message)
    );

}
