import {AvatarType, isAvatarType} from "./AvatarType";
import isString from "lodash/isString";

export interface ChatUser {

    readonly id     : string;
    readonly nick   : string;
    readonly avatar : AvatarType;

}

export function isChatUser (value: any) : value is ChatUser {

    return (
        !!value
        && isString(value?.id)
        && isString(value?.nick)
        && isAvatarType(value?.avatar)
    );

}
