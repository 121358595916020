import padStart from "lodash/padStart";

export class ChatUtils {

    public static getMessageId(id?: number): string {
        return `message-${Date.now()}${id ? '-' + id : ''}`;
    }

    public static getTime(): string {
        const now = new Date();
        return `${now.getHours()}:${padStart(`${now.getMinutes()}`, 2, '0')}`;
    }

}
